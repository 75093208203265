// GlobalStyle.js
import {createGlobalStyle} from 'styled-components';
import {inputStyle, labelStyle} from './FormControl';
import {customModalStyle} from './Modals';
import {robotoFontStyle, interFontStyle} from './Fonts';
import {NavbarCollapse} from './NavbarStyles';

export const GlobalStyle = createGlobalStyle`
  ${NavbarCollapse}
  ${inputStyle}
  ${labelStyle}
  ${customModalStyle}
  html body {
    background: #F4F5F9;
  }
  
  .statsText{
    font-size: 20px;
  }

  .list-group-item {
    cursor: pointer;
  }
  
  .FlexRow{
    display: flex;
    flex-wrap: wrap;
  }
  
  .FlexCol{
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: #343A40;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #343A40;
  }

  h5 {
    ${interFontStyle}
  }

  & .input-group-text {
    background: #fff;
    border: 1px solid #DEE2E6;

  }

  & #basic-nav-dropdown {
    color: #343A40;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ${robotoFontStyle}
  }

  & button.accordion-button {
    background: #fff !important;
    font-size: 16px;
    color: #667085 !important;
    ${interFontStyle}
  }

  & .accordion-body div:not(.rbt-input, .rbt-menu) {
    border: none
  }

  & .switch-row {
    border-radius: 8px;
  }

  & .switch-site-off .form-switch .form-check-input {
    border: 1px solid red !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255, 0, 0, 0.5)'/%3e%3c/svg%3e");

  }

  & .switch-row-default .form-switch .form-check-input {
    border: 1px solid rgba(0, 0, 0, .25) !important;
    background-color: rgba(0, 0, 0, .25) !important;
  }


  & .name-col {
    color: #667085
    font-size: 16px;
    text-transform: capitalize;
    ${interFontStyle}
  }
`;