// src/redux/features/envConfigSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Cctv } from 'lucide-react';

export interface EnvConfig {
    USER_POOL_ID: string;
    USER_POOL_CLIENT_ID: string;
    SLOTH_API_URL: string;
    OAUTH_DOMAIN: string;
    REDIRECT_SIGN_IN: string;
    REDIRECT_SIGN_OUT: string;
    TAG_URL: string;
    GBUCKET_SYNC: string;
    ENVIRONMENT: string;
    TENANT: string;
    CSS_URL: string;
    isLoaded: boolean;
    cssLoaded: boolean;
    UNDER_CONSTRUCTION: boolean;

}

const initialState: EnvConfig = {
    USER_POOL_ID: '',
    USER_POOL_CLIENT_ID: '',
    SLOTH_API_URL: '',
    OAUTH_DOMAIN: '',
    REDIRECT_SIGN_IN: '',
    REDIRECT_SIGN_OUT: '',
    TAG_URL: '',
    GBUCKET_SYNC: '',
    isLoaded: false,
    ENVIRONMENT: '',
    TENANT: '',
    CSS_URL: '',
    cssLoaded: false,
    UNDER_CONSTRUCTION: false

};

export const fetchEnvConfig = createAsyncThunk(
    'envConfig/fetch',
    async () => {
        // Fetch environment config
        
        const response = await fetch('/environment.json');
        console.log('response', response);
        const data = await response.json();
        console.log('data', data);

        // Handle CSS loading
        if (data.CSS_URL) {

            // Create and add new CSS link
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = data.CSS_URL;
            link.setAttribute('data-custom-css', 'true');
            document.head.appendChild(link);
        }

        return data;
    }
);

const envConfigSlice = createSlice({
    name: 'envConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEnvConfig.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoaded: true,
                cssLoaded: !!action.payload.CSS_URL
            };
        });
    },
});

export default envConfigSlice.reducer;