import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { IVariable } from "../../../types";
import { useFormContext } from "react-hook-form";
import { useConfiguratorContext } from "../../../hooks/configuratorContext";
import { debounce } from "lodash";
import MonacoEditor from 'components/editor/MonacoEditor';

type JSONType = {
    type: "JSON";
    default: string;
    value?: string;
    description: string;
    readOnly: boolean;
    hidden: boolean;
}

const ContextJSONVariable: FC<IVariable> = ({ name, location, groupId, moduleName, formIdentifier, isVariableGroup, header }) => {
    const { mergedConfig, addToLocalModuleConfig, addToLocalInventoryConfig } = useConfiguratorContext();
    const methods = useFormContext();

    const variableConfig = useMemo((): JSONType | undefined => {
        if (!location || !groupId || !moduleName) return;

        const configCopy = ["site", "preset"].includes(location)
            ? mergedConfig?.moduleConfig[groupId]
            : mergedConfig?.inventoryConfig[groupId];

        if (!configCopy) return;

        return isVariableGroup && header
            ? configCopy[moduleName]?.[header]?.[name]
            : configCopy[moduleName]?.[name];
    }, [mergedConfig]);

    const readOnly = variableConfig?.readOnly;
    const defaultValue = variableConfig?.default || '';
    const value = variableConfig?.value || defaultValue;

    // Debounced change handler to improve performance
    const debouncedHandleChange = useCallback(
        debounce((newJSON: string) => {
            // Invoke update functions based on the location
            if (location && ["site", "preset"].includes(location) && addToLocalModuleConfig) {
                addToLocalModuleConfig(moduleName, name, newJSON, formIdentifier);
            } else if (location && groupId && ["inventory", "preset-inventory"].includes(location) && addToLocalInventoryConfig) {
                addToLocalInventoryConfig(moduleName, name, groupId, newJSON, formIdentifier);
            }
        }, 500),
        [addToLocalModuleConfig, addToLocalInventoryConfig, location, groupId, moduleName, name, formIdentifier]
    );

    // Handle change event
    const handleChange = (newJSON = '') => {
        try {
            const newJSONFormatted = JSON.stringify(JSON.parse(newJSON));
    
            methods.setValue(formIdentifier, newJSONFormatted);
            debouncedHandleChange(newJSONFormatted);
        } catch (error) {
        }
    };

    useEffect(() => {
        methods.setValue(formIdentifier, value);
    }, [formIdentifier, methods, value]);

    // Register the input for form validation
    useEffect(() => {
        methods.register(formIdentifier);
    }, [formIdentifier, methods]);

    const editorValue = JSON.stringify(JSON.parse(value), null, 2);

    return (
        <div className='d-inline-block w-100' style={{"maxWidth": "500px"}}>
            <MonacoEditor
                language='json'
                value={editorValue}
                onChange={handleChange}
                readOnly={readOnly}
            />
        </div>
    );
};

export default ContextJSONVariable;
