import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useBlocker, Outlet } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { TabNav, TabNavItem } from '../../styles/TabNav';

import ModuleConfig from './ModuleConfig';
import InventoryConfig from '../inventory/InventoryConfig';
import Loader from '../Loader';
import ConfigModals from './ConfigModal';
import NavigationModal from './NavigationModal';
import PermissionButton from '../ButtonWithPermission';

import { useGetCustomConfig } from '../../hooks/slothCustomHooks';
import { ConfiguratorContext } from '../../hooks/configuratorContext';
import { ModuleDifference } from '../../types';

import {
    usePutPresetConfigMutation,
    usePutSiteConfigMutation,
} from '../../redux/api/slothAPI';

import {
    addMetaToInventoryConfig,
    changeInventoryConfig,
    comparePartialModuleConfig,
    contextAddSlot,
    contextDeleteSlot,
    convertFormDataToPartialConfig,
    createInventoryConfig,
    getMergedConfig,
    isNotConfigured,
    saveChanges,
} from '../../utils/config-helper';

import { removeObjectsWithEmptyFirstKeyAndNullValue } from '../../utils/utils';

interface IConfigurator {
    location: string;
}

const Configurator: FC<IConfigurator> = ({ location }) => {

    /**
     * ----------------------------------------------------------------
     * 1. ROUTING & QUERY PARAMETERS
     * ----------------------------------------------------------------
     */
    const routerParams = useParams();
    const siteId = routerParams?.site;
    const inventoryLocation = location === 'site' ? 'inventory' : 'preset-inventory';
    const queryArgs: any = {
        id: location === 'site' ? routerParams?.siteId : routerParams?.presetId,
    };

    /**
     * ----------------------------------------------------------------
     * 2. FETCH CONFIG DATA + RELEVANT STATE
     * ----------------------------------------------------------------
     */
    const {
        moduleGroups,
        config,
        slots,
        onlyPresetSlots,
        inactiveSlots,
        appliedPresetsConfig,
        isLoading,
        isAppliedPresetsLoading,
    } = useGetCustomConfig(queryArgs.id, location);

    const postQuery =
        location === 'site' ? usePutSiteConfigMutation : usePutPresetConfigMutation;
    const [postConfig, { isLoading: postConfigIsLoading }] = postQuery();

    /**
     * ----------------------------------------------------------------
     * 3. REACT HOOK FORM
     * ----------------------------------------------------------------
     */
    const methods = useForm();
    const formRef = useRef<HTMLFormElement>(null);

    /**
     * ----------------------------------------------------------------
     * 4. LOCAL STATE
     * ----------------------------------------------------------------
     */
        // Tab state
    const [activeTab, setActiveTab] = useState('site');

    // Modals (progress & confirmation)
    const [progressModalVisible, setProgressModalVisible] = useState(false);
    const changeProgressModalVisibility = () => setProgressModalVisible(!progressModalVisible);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const changeConfirmationModalVisibility = () => setConfirmationModalVisible(!confirmationModalVisible);

    // Track unsaved changes & navigation
    const [allowNavigation, setAllowNavigation] = useState(true);
    const [navigationModalVisible, setNavigationModalVisible] = useState(false);
    const [pendingPath, setPendingPath] = useState<string | null>(null);

    // Track config changes & messages
    const [configChanges, setConfigChanges] = useState<ModuleDifference[]>([]);
    const [inventoryChanges, setInventoryChanges] = useState<ModuleDifference[]>([]);
    const [statusMessage, setStatusMessage] = useState('');

    // Local config state
    const [localModuleConfig, setLocalModuleConfig] = useState<any>();
    const [localInventoryConfig, setLocalInventoryConfig] = useState<any>();
    const [deletedPresetSlots, setDeletedPresetSlots] = useState<string[]>([]);
    const [mergedConfig, setMergedConfig] = useState<any>(undefined);

    // Final config to post
    const currentConfigToPost = useRef({
        moduleConfig: {},
        inventoryConfig: {},
        gbucketUrls: undefined,
    });

    /**
     * ----------------------------------------------------------------
     * 5. MERGE CONFIG & INITIALIZE ON LOAD
     * ----------------------------------------------------------------
     */
    useMemo(() => {
        if (
            config?.builderConfig &&
            localModuleConfig &&
            localInventoryConfig &&
            appliedPresetsConfig &&
            location
        ) {
            setMergedConfig(
                getMergedConfig(
                    config?.builderConfig,
                    localModuleConfig,
                    localInventoryConfig,
                    appliedPresetsConfig,
                    location,
                    config?.gbucket
                )
            );
        }
    }, [config?.gbucket, localModuleConfig, localInventoryConfig, appliedPresetsConfig, location, config?.builderConfig]);

    useEffect(() => {
        setLocalModuleConfig(config?.moduleConfig || {});
        setLocalInventoryConfig({
            inventoryConfig: config?.inventoryConfig || {},
            slots,
            onlyPresetSlots,
            inactiveSlots,
        });
    }, [config?.inventoryConfig, config?.moduleConfig, slots, onlyPresetSlots, inactiveSlots]);

    /**
     * ----------------------------------------------------------------
     * 6. NAVIGATION BLOCKING
     * ----------------------------------------------------------------
     */
    const blocker: any = useBlocker(!allowNavigation);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            const { pathname } = blocker.location || {};
            setPendingPath(pathname || null);
            setNavigationModalVisible(true);
        }
    }, [blocker.state, blocker.location]);

    const confirmNavigation = () => {
        setNavigationModalVisible(false);
        setAllowNavigation(true);
        blocker.proceed(); // let React Router continue
        setPendingPath(null);
    };

    const cancelNavigation = () => {
        setNavigationModalVisible(false);
        setPendingPath(null);
        blocker.reset(); // stay on current page
    };

    /**
     * ----------------------------------------------------------------
     * 7. SLOT MANIPULATIONS
     * ----------------------------------------------------------------
     */
    const deleteSlot = (inventory: string) => {
        contextDeleteSlot(
            inventory,
            localInventoryConfig,
            setLocalInventoryConfig,
            deletedPresetSlots,
            setDeletedPresetSlots
        );
    };

    const addSlot = (name: string) => {
        contextAddSlot(name, localInventoryConfig, setLocalInventoryConfig);
    };

    /**
     * ----------------------------------------------------------------
     * 8. UPDATE CONFIG FUNCTIONS
     * ----------------------------------------------------------------
     */
    const addToLocalModuleConfig = (
        module: string,
        variable: string,
        value: any,
        formIdentifier: string
    ) => {
        if (allowNavigation) {
            setAllowNavigation(false);
        }
        const result = structuredClone(localModuleConfig);
        result[module] = {
            vars: {
                ...result[module]?.vars,
                [variable]: value,
            },
        };
        setLocalModuleConfig(result);
    };

    const addToLocalInventoryConfig = (
        module: string,
        variable: string,
        slot: string,
        value: any,
        formIdentifier: string
    ) => {
        if (allowNavigation) {
            setAllowNavigation(false);
        }
        const result = structuredClone(localInventoryConfig);
        if (!result.inventoryConfig[slot]) {
            result.inventoryConfig[slot] = {};
        }
        result.inventoryConfig[slot][module] = {
            ...result.inventoryConfig[slot][module],
            vars: {
                ...result.inventoryConfig[slot][module]?.vars,
                [variable]: value,
            },
        };
        setLocalInventoryConfig(result);
    };

    const unsetVariable = (
        module: string,
        variable: string,
        configLocation: string,
        inventory: string,
        formIdentifier: string
    ) => {
        methods.unregister(formIdentifier);

        // Remove from localModuleConfig
        let moduleValue = localModuleConfig && localModuleConfig[module]?.vars[variable];
        if (
            (moduleValue ||
                moduleValue === false ||
                moduleValue === 0 ||
                moduleValue === '' ||
                isNaN(moduleValue)) &&
            ['site', 'preset'].includes(configLocation) &&
            module
        ) {
            const result = structuredClone(localModuleConfig);
            delete result[module].vars[variable];
            if (Object.keys(result[module].vars).length === 0) {
                delete result[module];
            }
            setLocalModuleConfig(result);
        }

        // Remove from localInventoryConfig
        let value = localInventoryConfig?.inventoryConfig?.[inventory]?.[module]?.vars[variable];
        if (
            (value ||
                value === false ||
                value === 0 ||
                value === '' ||
                isNaN(value)) &&
            ['inventory', 'preset-inventory'].includes(configLocation) &&
            inventory
        ) {
            const result = structuredClone(localInventoryConfig.inventoryConfig);
            delete result[inventory][module].vars[variable];
            if (Object.keys(result[inventory][module].vars).length === 0) {
                delete result[inventory][module];
            }
            setLocalInventoryConfig({
                ...localInventoryConfig,
                inventoryConfig: result,
            });
        }
    };

    /**
     * ----------------------------------------------------------------
     * 9. SAVE CHANGES
     * ----------------------------------------------------------------
     */
    const handleSaveChanges = (message: string) => {
        saveChanges(
            message,
            {
                moduleConfig: currentConfigToPost.current.moduleConfig,
                inventoryConfig: currentConfigToPost.current.inventoryConfig,
                gbucketUrls: currentConfigToPost.current.gbucketUrls,
            },
            setStatusMessage,
            setConfigChanges,
            changeConfirmationModalVisibility,
            changeProgressModalVisibility,
            postConfig,
            location === 'site' ? routerParams.siteId : routerParams.presetId
        );
        // Re-allow navigation upon success
        setAllowNavigation(true);
        methods.reset();
    };

    /**
     * ----------------------------------------------------------------
     * 10. FORM SUBMISSION
     * ----------------------------------------------------------------
     */
    const onSubmit = (formData: any) => {
        let localConfigChanges: any = [];
        let localInventoryChanges: any = [];

        // Compare partial module config with original
        let formModuleConfig = convertFormDataToPartialConfig(
            formData,
            undefined,
            location,
            config?.gbucket
        );
        localConfigChanges = comparePartialModuleConfig(
            formModuleConfig,
            config.originalModuleConfig
        );

        // Compare inventory changes
        let allSlots = localInventoryConfig.slots;
        allSlots.forEach((inventory: string) => {
            let inventoryChange: any = [];
            if (localInventoryConfig.onlyPresetSlots.includes(inventory)) {
                if (!isNotConfigured(inventory, formData, inventoryLocation)) {
                    inventoryChange = comparePartialModuleConfig(
                        convertFormDataToPartialConfig(formData, inventory, inventoryLocation),
                        {}
                    );
                } else {
                    return; // skip
                }
            } else if (
                config.originalInventoryConfig[inventory] &&
                localInventoryConfig.inventoryConfig[inventory]
            ) {
                inventoryChange = comparePartialModuleConfig(
                    convertFormDataToPartialConfig(formData, inventory, inventoryLocation),
                    config.originalInventoryConfig[inventory]
                );
            } else if (!deletedPresetSlots.includes(inventory)) {
                // brand new slot
                config.originalInventoryConfig[inventory] = createInventoryConfig(
                    formData,
                    inventory,
                    inventoryLocation
                );
                if (!localInventoryConfig.onlyPresetSlots.includes(inventory)) {
                    inventoryChange = 'new';
                }
            } else {
                inventoryChange = null;
            }
            localInventoryChanges.push({
                [inventory]: inventoryChange == null ? null : inventoryChange || [],
            });
        });

        // Mark inactive slots as null
        if (localInventoryConfig.inactiveSlots?.length > 0) {
            localInventoryConfig.inactiveSlots.forEach((slot: string) => {
                localInventoryChanges.push({ [slot]: null });
            });
        }

        // Prepare final config to post
        currentConfigToPost.current.moduleConfig = structuredClone(formModuleConfig);
        currentConfigToPost.current.inventoryConfig = addMetaToInventoryConfig(
            localInventoryConfig,
            structuredClone(
                changeInventoryConfig(
                    config.originalInventoryConfig,
                    localInventoryChanges,
                    inventoryLocation
                )
            ),
            formData?.Inventory_Descriptions
        );

        // If gbucket is present
        if (config?.gbucket) {
            currentConfigToPost.current.gbucketUrls =
                config?.gbucketUrls?.concat(config?.gbucket.results[0].gbucketUrls) ||
                config?.gbucket.results[0].gbucketUrls;
        }

        // Add changed inventory descriptions
        if (formData.Inventory_Descriptions) {
            for (const key in formData.Inventory_Descriptions) {
                localConfigChanges.push({
                    slot: key,
                    description: formData.Inventory_Descriptions[key],
                    oldDescription: config.originalInventoryConfig[key]?.meta?.description || '',
                    type: 'description',
                });
            }
        }

        // Mark deleted slots
        deletedPresetSlots.forEach((slot) => {
            localInventoryChanges.push({ [slot]: 'delete' });
        });

        removeObjectsWithEmptyFirstKeyAndNullValue(localInventoryChanges);

        // Update state & open confirmation modal
        setConfigChanges(localConfigChanges);
        setInventoryChanges(localInventoryChanges);
        changeConfirmationModalVisibility();
    };

    // Programmatic form submit
    const handleFormSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    /**
     * ----------------------------------------------------------------
     * 11. CONTEXT VALUE
     * ----------------------------------------------------------------
     */
    const contextValue = useMemo(() => {
        const builderConfig = config?.builderConfig;
        const mySlots = localInventoryConfig?.slots;
        const myOnlyPresetSlots = localInventoryConfig?.onlyPresetSlots;
        const myInactiveSlots = localInventoryConfig?.inactiveSlots;

        return {
            moduleGroups,
            builderConfig,
            localModuleConfig,
            localInventoryConfig,
            location,
            slots: mySlots,
            onlyPresetSlots: myOnlyPresetSlots,
            inactiveSlots: myInactiveSlots,
            mergedConfig,
            deleteSlot,
            addSlot,
            addToLocalModuleConfig,
            addToLocalInventoryConfig,
            unsetVariable,
        };
        // eslint-disable-next-line
    }, [
        config?.builderConfig,
        localInventoryConfig,
        moduleGroups,
        localModuleConfig,
        location,
        mergedConfig,
        deleteSlot,
        addSlot,
        addToLocalModuleConfig,
        addToLocalInventoryConfig,
        unsetVariable,
        config?.gbucket,
    ]);

    /**
     * ----------------------------------------------------------------
     * 12. LOADING CHECK
     * ----------------------------------------------------------------
     */
    if (isLoading || isAppliedPresetsLoading) {
        return <Loader />;
    }

    /**
     * ----------------------------------------------------------------
     * 13. RENDER
     * ----------------------------------------------------------------
     */
    return (
        <>
            <ConfiguratorContext.Provider value={contextValue}>
                <FormProvider {...methods}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <h3>{siteId} configuration</h3>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <PermissionButton variant="success" onClick={handleFormSubmit}>
                                    Save Changes
                                </PermissionButton>
                            </Col>
                        </Row>

                        <Row>
                            <Form onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
                                <TabNav justify fill variant="tabs">
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'site' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('site');
                                            }}
                                        >
                                            <i className="bi bi-sliders" /> Site
                                        </a>
                                    </TabNavItem>
                                    <TabNavItem>
                                        <a
                                            href="#"
                                            className={activeTab === 'inventory' ? 'nav-link active' : 'nav-link'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab('inventory');
                                            }}
                                        >
                                            <i className="bi bi-folder" /> Inventory
                                        </a>
                                    </TabNavItem>
                                </TabNav>

                                {/* Tab Content: Site */}
                                <div style={{ display: activeTab === 'site' ? 'block' : 'none' }}>
                                    <ModuleConfig location={location} />
                                </div>

                                {/* Tab Content: Inventory */}
                                <div style={{ display: activeTab === 'inventory' ? 'block' : 'none' }}>
                                    <InventoryConfig
                                        location={location === 'site' ? 'inventory' : 'preset-inventory'}
                                    />
                                </div>
                            </Form>
                        </Row>
                    </Container>
                </FormProvider>

                {/* Confirmation Modal for Saving Changes */}
                <ConfigModals
                    progressModalVisible={progressModalVisible}
                    changeProgressModalVisibility={changeProgressModalVisibility}
                    confirmationModalVisible={confirmationModalVisible}
                    changeConfirmationModalVisibility={changeConfirmationModalVisibility}
                    saveChanges={handleSaveChanges}
                    configChanges={configChanges}
                    inventoryChanges={inventoryChanges}
                    statusMessage={statusMessage}
                    isLoading={postConfigIsLoading}
                    configType="context"
                    setConfigChanges={() => setConfigChanges([])}
                />
            </ConfiguratorContext.Provider>

            {/* Navigation Modal to confirm leaving without saving */}
            <NavigationModal
                navigationModalVisible={navigationModalVisible}
                setNavigationModalVisible={cancelNavigation}
                newPath={pendingPath || ''}
                onConfirm={confirmNavigation}
            />
            <Outlet />
        </>
    );
};

// @ts-ignore
Configurator.whyDidYouRender = true;
export default Configurator;
