import React, { FC, useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { IVariable } from "../../../types";
import { useFormContext } from "react-hook-form";
import { changeOriginInName } from "../../../utils/config-helper";
import { useConfiguratorContext } from "../../../hooks/configuratorContext";

const ContextInputVariable: FC<IVariable> = ({
                                                 name,
                                                 moduleName,
                                                 formIdentifier,
                                                 isVariableGroup,
                                                 header,
                                                 groupId,
                                                 location,
                                             }) => {
    const methods = useFormContext();
    const { mergedConfig, addToLocalModuleConfig, addToLocalInventoryConfig } =
        useConfiguratorContext();

    // Track the current "localName" and its "previous" name
    const [localName, setLocalName] = useState(formIdentifier);
    const prevLocalNameRef = useRef(localName);

    // UI states for validation feedback
    const [isInValid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Grab the relevant slice of merged config
    const mergedConfigCopy =
        location &&
        groupId &&
        (["site", "preset"].includes(location)
            ? mergedConfig?.moduleConfig[groupId]
            : mergedConfig?.inventoryConfig[groupId]);

    // Get the variable config (either as a group or single)
    const variableConfig =
        groupId &&
        (isVariableGroup && header
            ? mergedConfigCopy?.[moduleName]?.[header]?.[name]
            : mergedConfigCopy?.[moduleName]?.[name]);

    // Derive type
    const configType = variableConfig?.type?.toLowerCase() ?? "";
    const isUrl = configType === "url";
    const type = isUrl ? "string" : configType;

    // Other constraints
    const min = variableConfig?.min;
    const max = variableConfig?.max;
    const step = variableConfig?.step;
    const readOnly = variableConfig?.readOnly;

    // Track the current value
    const [value, setValue] = useState(variableConfig?.value);

    // Refs for controlling textarea height
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    // -- 1) Update localName on origin change (but only if it *actually* changes)
    useEffect(() => {
        const newLocalName = changeOriginInName(formIdentifier, variableConfig?.origin);
        if (newLocalName !== localName) {
            setLocalName(newLocalName);
        }
    }, [variableConfig?.origin, formIdentifier, localName]);

    // -- 2) Whenever localName changes (or value changes), re-register with react-hook-form
    useEffect(() => {
        // If the localName changed, unregister the old name first
        if (prevLocalNameRef.current !== localName) {
            methods.unregister(prevLocalNameRef.current);
            prevLocalNameRef.current = localName;
        }

        // Register the new name (with numeric rules if needed)
        if (type === "number") {
            methods.register(localName, {
                valueAsNumber: true,
                min,
                max,
            });
        } else {
            methods.register(localName);
        }

        // Update our local value
        setValue(variableConfig?.value);
        // Update react-hook-form's value
        if (variableConfig?.value && typeof variableConfig?.value === "object") {
            methods.setValue(localName, JSON.stringify(variableConfig?.value));
        } else {
            methods.setValue(localName, variableConfig?.value);
        }

        // Adjust textarea height
        if (inputRef.current && type !== "number") {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [
        localName,
        variableConfig?.value,
        methods,
        type,
        min,
        max,
    ]);

    // Validation helpers
    const resetValidation = () => {
        setIsInvalid(false);
        setErrorMessage("");
    };

    const runValidation = (val: string) => {
        resetValidation();

        // Required check (example)
        if (variableConfig?.required && !val) {
            setIsInvalid(true);
            setErrorMessage("This field is required.");
            return;
        }

        // Numeric checks
        if (type === "number") {
            const numericValue = parseFloat(val);
            if (isNaN(numericValue)) {
                setIsInvalid(true);
                setErrorMessage("Please enter a valid number.");
                return;
            }
            if (min !== undefined && numericValue < min) {
                setIsInvalid(true);
                setErrorMessage(`Value cannot be less than ${min}.`);
                return;
            }
            if (max !== undefined && numericValue > max) {
                setIsInvalid(true);
                setErrorMessage(`Value cannot be greater than ${max}.`);
                return;
            }
        }
    };

    // OnChange handlers
    const handleChangeText = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        event.preventDefault();
        const inputValue = event.target.value;

        const newLocalName = location
            ? changeOriginInName(formIdentifier, location)
            : localName;

        methods.setValue(newLocalName, inputValue);
        setValue(inputValue);

        // Update local/preset or inventory config
        if (addToLocalModuleConfig && location && ["site", "preset"].includes(location)) {
            addToLocalModuleConfig(moduleName, name, inputValue, newLocalName);
        }
        if (
            location &&
            groupId &&
            ["inventory", "preset-inventory"].includes(location) &&
            addToLocalInventoryConfig
        ) {
            addToLocalInventoryConfig(moduleName, name, groupId, inputValue, newLocalName);
        }

        // Run validation
        runValidation(inputValue);
    };

    const handleChangeNumber = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        event.preventDefault();
        // Replace commas with dots
        let inputValue = event.target.value.replace(",", ".");
        const newLocalName = location
            ? changeOriginInName(formIdentifier, location)
            : localName;

        methods.setValue(newLocalName, inputValue);
        setValue(inputValue);

        // Update config
        if (addToLocalModuleConfig && location && ["site", "preset"].includes(location)) {
            addToLocalModuleConfig(moduleName, name, inputValue, newLocalName);
        }
        if (
            location &&
            groupId &&
            ["inventory", "preset-inventory"].includes(location) &&
            addToLocalInventoryConfig
        ) {
            addToLocalInventoryConfig(moduleName, name, groupId, inputValue, newLocalName);
        }

        // Run validation
        runValidation(inputValue);
    };

    const inputStyle: React.CSSProperties = {
        maxWidth: "500px",
        minHeight: "38px",
        overflow: "hidden",
    };

    return (
        <div key={name + moduleName}>
            {type === "number" ? (
                <Form.Control
                    className="float-start me-1"
                    {...methods.register(localName, {
                        valueAsNumber: true,
                        min,
                        max,
                    })}
                    name={localName}
                    value={value ?? ""}
                    onChange={handleChangeNumber}
                    type="number"
                    placeholder={
                        variableConfig?.value === 0 || variableConfig?.value === undefined
                            ? variableConfig?.default
                            : ""
                    }
                    isInvalid={isInValid}
                    disabled={readOnly || false}
                    step={step || ".01"}
                    style={{ ...inputStyle, height: "38px" }}
                    ref={inputRef as React.RefObject<HTMLInputElement>}
                />
            ) : (
                <Form.Control
                    className="float-start me-1"
                    {...methods.register(localName)}
                    name={localName}
                    onChange={handleChangeText}
                    type="text"
                    placeholder={
                        (type === "object" || type === "array"
                            ? JSON.stringify(variableConfig?.default)
                            : variableConfig?.default) || ""
                    }
                    value={
                        value
                            ? (type === "object" || type === "array") && typeof value === "object"
                                ? JSON.stringify(value)
                                : value
                            : ""
                    }
                    isInvalid={isInValid}
                    disabled={readOnly || false}
                    style={inputStyle}
                    as="textarea"
                    rows={1}
                    ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                />
            )}
            <Form.Control.Feedback
                type="invalid"
                style={{ clear: "both", float: "left" }}
            >
                {errorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default ContextInputVariable;
