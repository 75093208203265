/**
 * Wrapper around a Monaco code editor with a lazy-loading mechanism. It provides a read-only preview mode and an interactive editor mode.
 * 
 * Features:
 * - Lazy loading of the Monaco editor to improve performance.
 * - Preview mode that displays the code as plain text with line numbers.
 * - Editor mode that allows for full code editing with syntax highlighting.
 * - Dynamic height adjustment based on the number of lines in the code.
 * - Error display for validation errors with line and column numbers.
 */
import { lazy, Suspense, useCallback, useState } from "react";

// lazy load the Monaco editor to improve performance
const Editor = lazy(() => import('@monaco-editor/react'));

// maximum number of lines to display in the editor by default
const DEFAULT_MAX_LINES = 25;
// height of a line in the editor in pixels
const MONACO_LINE_HEIGHT = 18;
// padding to include when calculating the height of the editor
const MONACO_EDITOR_PADDING = 2;

// validation marker for monaco editor
// truncated to only include the fields we need
type Marker = {
    message: string;
    startLineNumber: number;
    startColumn: number;
    endLineNumber: number;
    endColumn: number;
}

type MonacoEditorProps = {
    // The maximum number of lines to display in the editor
    // This is used to calculate the height of the editor
    maxNumberOfLines?: number;
    // This is the value that will be displayed in the editor
    value?: string;
    // The language for syntax highlighting
    language: string; // e.g. "json", "yaml", "javascript"
    readOnly?: boolean;
    // A function that will be called when the value of the editor changes
    // This function will receive the new value as a parameter
    onChange?: (value?: string) => void;
}

export default function MonacoEditor({maxNumberOfLines = DEFAULT_MAX_LINES, value, language, readOnly = false, onChange}: MonacoEditorProps) {
    const [showEditor, setShowEditor] = useState(false);
    const [errors, setErrors] = useState<Marker[]>([]);

    // handle click into the preview area
    // to load the editor
    // this is used to avoid loading the editor
    // when the user is not interacting with it
    const handleEditorLoad = useCallback(() => {
        if (!readOnly) {
            setShowEditor(true);
        }
    }, [readOnly]);
    
    // clamp the max number of lines to a reasonable value
    const numberOfLines = Math.min(maxNumberOfLines, value?.split('\n').length || 0);
    // calculate the height of the editor based on the number of lines
    // and the line height
    const editorHeight = `${MONACO_LINE_HEIGHT * numberOfLines + MONACO_EDITOR_PADDING}px`;

    return (
        <>
            {!showEditor && (
                <div className="font-monospace bg-light border" onClick={handleEditorLoad} style={{"fontSize": "12px"}}>
                    {
                        value?.split('\n')
                            .map((line, index) => (
                                <div className="d-flex" style={{whiteSpace: "pre", height: "18px"}} key={index}>
                                    <div className="text-black-50 text-end" style={{width: "62px", paddingRight: "18px"}}>{index + 1}</div>
                                    <div>{line}</div>
                                </div>
                            )) || ''
                    }
                </div>
            )}

            {showEditor && (
                <Suspense fallback={<div className="loading">Loading...</div>}>
                    <Editor
                        className="border"
                        height={editorHeight}
                        language={language}
                        value={value}
                        onChange={onChange}
                        onValidate={setErrors}
                        options={{
                            scrollBeyondLastLine: false,
                            overviewRulerLanes: 0,
                            minimap: {
                                enabled: false
                            }
                        }}
                    />
                </Suspense>
            )}

            {errors.length > 0 && (
                <div className="invalid-feedback d-block">
                    <div style={{fontWeight: 'bold'}}>Errors:</div>
                    {errors.map((error, index) => (
                        <div key={index}>
                            Ln {error.startLineNumber}, Col {error.startColumn}: {error.message}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}