import React, {FC, useEffect, useState} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import {Button} from "react-bootstrap";
import {MobileNavIcon, ResponsiveNav} from "../../styles/TabNav";

interface ConfigNavigationProps {
    elements: string[];
    inactiveElements?: string[];
    deleteElement?: any;
    addElement?: any;
    selectedElements: string[];
    handleSelect: (element: string) => void;
    inventoryConfig?: any | undefined;
    inventoryDescriptions?: any;
}

const ConfigNavigation: FC<ConfigNavigationProps> = ({
                                                         elements,
                                                         inactiveElements,
                                                         deleteElement,
                                                         addElement,
                                                         selectedElements,
                                                         handleSelect,
                                                         inventoryConfig,
                                                         inventoryDescriptions
                                                     }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [localElements, setLocalElements] = useState<string[]>([]);
    useEffect(() => {
        if (elements !== localElements) {
            setLocalElements(elements);
        }
    }, [elements]);

    return (
        <>
            <MobileNavIcon isOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                All Inventory <i className={`bi bi-chevron-${isMenuOpen ? 'up' : 'down'}`}></i>
            </MobileNavIcon>
            <ResponsiveNav isOpen={isMenuOpen}>
                <ListGroup defaultActiveKey="#test100">
                    {localElements.map((element, index) => {
                        return (
                            <ListGroup.Item
                                className="d-flex flex-column"
                                key={index}
                                onClick={() => handleSelect(element)}
                                active={selectedElements.includes(element)}
                            >
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div>{element}</div>
                                    {deleteElement && (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <Button onClick={() => deleteElement(element)} variant="outline-danger">
                                                <i className="bi bi-trash"></i>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                {inventoryConfig?.inventoryConfig[element]?.meta?.description && (
                                    <div className="mt-2 text-muted description">
                                        {inventoryConfig.inventoryConfig[element].meta.description}
                                    </div>
                                )}
                            </ListGroup.Item>
                        );
                    })}
                    {inactiveElements && inactiveElements.map((element, index) => {
                        return (
                            <ListGroup.Item className="d-flex justify-content-between align-items-center" key={index}>
                                {element}
                                {addElement &&
                                    <div onClick={(e) => e.stopPropagation()}>
                                        <Button
                                            onClick={() => addElement(element)}
                                            variant="outline-success">
                                            <span className="bi bi-plus">  </span>
                                        </Button>
                                    </div>
                                }
                            </ListGroup.Item>
                        );
                    })}
                    {
                        !localElements?.length && !inactiveElements?.length &&
                        <p>
                            No elements to display
                        </p>
                    }
                </ListGroup>
            </ResponsiveNav>
        </>
    );
};

export default ConfigNavigation;