import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from 'react-router-dom';

import Header from "./components/Header";
import NoMatch from "./components/NoMatch";
import { useAuth } from './config/AuthContext';
import withAuthorization from './config/withAuthorization';
import UnderConstructionWrapper from 'components/UnderConstructionWrapper';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';

import { GlobalStyle } from './styles/GlobalStyle';
import { FontLoader } from './styles/Fonts';
import { ErrorBoundary } from './components/ErrorBoundary';
import Sites from './components/sites/Sites';
import Site from './components/sites/Site';
import Configurator from './components/configuration/Configurator';
import HomePage from "./components/home/HomePage";
import Publishers from "./components/publishers/Publishers";
import SiteSettings from "./components/sites/SiteSettings";
import TagInTag from "./components/tags/TagInTag";
import Tags from "./components/tags/Tags";
import InventoryConfig from "./components/inventory/InventoryConfig";
import Presets from "./components/presets/Presets";
import Preset from "./components/presets/Preset";
import PresetSelector from "./components/presets/PresetSelector";
import ModuleConfig from "./components/configuration/ModuleConfig";
import PresetSettings from "./components/presets/PresetSettings";
import Statistics from "./components/statistics/Stats";

function RootLayout({
                        userName,
                        signOut,
                    }: {
    userName: string;
    signOut: () => void;
}) {
    return (
        <UnderConstructionWrapper>
            <FontLoader />
            <GlobalStyle />
            <Header userName={userName} signOut={signOut} />
            {/* Directly rendering <Outlet /> without Suspense */}
            <Outlet />
        </UnderConstructionWrapper>
    );
}

function App() {
    const { userGroups, userName, handleSignOut } = useAuth();

    // Create protected components
    const protectedComponents = {
        ProtectedHome: withAuthorization(HomePage, userGroups),
        ProtectedSites: withAuthorization(Sites, userGroups),
        ProtectedPresets: withAuthorization(Presets, userGroups),
        ProtectedPreset: withAuthorization(Preset, userGroups),
        ProtectedPublishers: withAuthorization(Publishers, userGroups),
        ProtectedSite: withAuthorization(Site, userGroups),
        ProtectedSiteSettings: withAuthorization(SiteSettings, userGroups),
        ProtectedTags: withAuthorization(Tags, userGroups),
        ProtectedInventoryConfig: withAuthorization(InventoryConfig, userGroups),
        ProtectedPresetSelector: withAuthorization(PresetSelector, userGroups),
        ProtectedConfigurator: withAuthorization(Configurator, userGroups),
        ProtectedModuleConfig: withAuthorization(ModuleConfig, userGroups),
        ProtectedPresetSettings: withAuthorization(PresetSettings, userGroups),
        ProtectedStatistics: withAuthorization(Statistics, userGroups),
        ProtectedTagInTag: withAuthorization(TagInTag, userGroups),
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <RootLayout userName={userName} signOut={handleSignOut} />
            ),
            children: [
                { index: true, element: <protectedComponents.ProtectedHome /> },
                { path: 'sites', element: <protectedComponents.ProtectedSites /> },
                { path: 'presets', element: <protectedComponents.ProtectedPresets /> },
                { path: 'publishers', element: <protectedComponents.ProtectedPublishers /> },
                {
                    path: 'presets/:presetId',
                    element: <protectedComponents.ProtectedPreset />,
                    children: [
                        { index: true, element: <protectedComponents.ProtectedConfigurator location="preset" /> },
                        { path: 'settings', element: <protectedComponents.ProtectedPresetSettings /> },
                        { path: 'configurator', element: <protectedComponents.ProtectedConfigurator location="preset" /> },
                    ],
                },
                {
                    path: 'site/:siteId',
                    element: <protectedComponents.ProtectedSite />,
                    children: [
                        { index: true, element: <Configurator location="site" /> },
                        {
                            path: 'configurator',
                            element: (
                                <ErrorBoundary>
                                    <Configurator location="site" />
                                </ErrorBoundary>
                                
                            ),
                        },
                        { path: 'presets', element: <PresetSelector /> },
                        { path: 'stats', element: <Statistics /> },
                        { path: 'settings', element: <SiteSettings /> },
                        { path: 'tags', element: <Tags /> },
                        { path: '*', element: <NoMatch errorMessage="This Page Doesn't Exist" /> },
                    ],
                },
                { path: '*', element: <NoMatch errorMessage="This Page Doesn't Exist" /> },
            ],
        },
    ]);

    return (
        <ErrorBoundary>
            <RouterProvider router={router} />
        </ErrorBoundary>
    );
}

export default App;
