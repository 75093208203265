import React, {FC, ReactNode, useState} from 'react';
import {Col, Container, Form, Row, Badge, Button} from "react-bootstrap"
import ContextBooleanVariable from "./ContextBooleanVariable"
import ContextInputVariable from "./ContextInputVariable"
import ContextSelectVariable from './ContextSelectVariable';
import ContextJSONVariable from './ContextJSONVariable';
import {ContextVariablesProps} from "../../../types";
import {useSelector} from 'react-redux';
import Description from "../../Description";

import {Link} from "react-router-dom";
import {predefinedOrigins} from "../../../utils/utils";
import {useConfiguratorContext} from "../../../hooks/configuratorContext";


//Variable are all variable form components based on their type. Containing a description as well
const ContextVariable: FC<ContextVariablesProps> = ({
                                                        name,
                                                        moduleName,
                                                        isVariableGroup,
                                                        header,
                                                        groupId,
                                                        location

                                                    }) => {


    const [switchActive, setSwitchActive] = useState(false);
    const {mergedConfig, unsetVariable} = useConfiguratorContext()


    const mergedConfigCopy = location && groupId && (["site", "preset"].includes(location) ? mergedConfig?.moduleConfig[groupId] : mergedConfig?.inventoryConfig[groupId])
    const variableConfig = groupId && (isVariableGroup && header ? mergedConfigCopy[moduleName]?.[header][name] : mergedConfigCopy[moduleName]?.[name])

    const privilege = variableConfig?.displayPrivilege || []

    const type = variableConfig?.type?.toLowerCase()
    const slot = variableConfig?.slot

    const userRole = useSelector((state: any) => state.userRole.role)

    const switchRowClass = variableConfig?.origin === 'default' ? 'switch-row-default' : '';
    const switchActiveClass = switchActive ? 'switch-active' : '';
    const siteClass = (type === "boolean" && !variableConfig?.value && variableConfig?.origin) === 'site' ? 'switch-site-off' : '';

    const formIdentifier = moduleName + ".vars." + name + "." + type + "__" + variableConfig?.origin + (slot ? "__" + slot : "")

    // Handler function to toggle switch state
    const handleToggleSwitch = (isChecked: boolean) => {
        setSwitchActive(isChecked);
    };

    if(!(type && (!userRole || privilege.includes(userRole) || privilege.length === 0))) {
        return null;
    }

    let formVariable: ReactNode;
    let unsetClass = 'p-1 pt-0 pb-0 border-1 bi bi-arrow-counterclockwise';

    switch(type) {
        case 'boolean':
            formVariable = (
                <ContextBooleanVariable
                    name={name}
                    moduleName={moduleName}
                    formIdentifier={formIdentifier}
                    isVariableGroup={isVariableGroup}
                    header={header}
                    onToggle={handleToggleSwitch}
                    location={location}
                    groupId={groupId}
                    />
            );
            unsetClass += ' m-1';
            break;
        case 'select':
            formVariable = (
                <ContextSelectVariable
                    name={name}
                    moduleName={moduleName}
                    formIdentifier={formIdentifier}
                    isVariableGroup={isVariableGroup}
                    header={header}
                    location={location}
                    groupId={groupId}
                />
            );
            unsetClass += ' ms-2';
            break;
        case 'json':
            formVariable = (
                <ContextJSONVariable
                    name={name}
                    moduleName={moduleName}
                    formIdentifier={formIdentifier}
                    isVariableGroup={isVariableGroup}
                    header={header}
                    location={location}
                    groupId={groupId}
                />
            );
            unsetClass += ' ms-2 align-top';
            break;
        default:
            formVariable = (
                <ContextInputVariable
                    name={name}
                    moduleName={moduleName}
                    formIdentifier={formIdentifier}
                    isVariableGroup={isVariableGroup}
                    header={header}
                    groupId={groupId}
                    location={location}
                />
            );
            unsetClass += ' ms-2';
            break;
    }

    return (
        <Container className="p-0 m-0">
            <Form.Group as={Row} className={`mb-3 switch-row d-flex align-items-center ${switchRowClass} ${switchActiveClass} ${siteClass}`}>
                <Col xs={1}>
                    {(!predefinedOrigins.includes(variableConfig?.origin) && (
                        <Link
                            to={"/presets/" + variableConfig?.origin + ".preset.js/configurator"}
                            style={{color: "black"}}
                            target="_blank"
                        >
                            <Badge
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap", // Prevents text wrapping
                                    maxWidth: "100%", // Ensures it fills the parent's width
                                }}
                                bg={
                                    variableConfig?.origin === "default"
                                        ? "secondary"
                                        : variableConfig?.origin === "gbucket"
                                            ? "danger"
                                            : "primary"
                                }
                            >
                                {variableConfig?.origin}
                            </Badge>
                        </Link>
                    )) || (
                        <Badge
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap", // Prevents text wrapping
                                maxWidth: "100%", // Ensures it fills the parent's width
                            }}
                            bg={
                                variableConfig?.origin === "default"
                                    ? "secondary"
                                    : variableConfig?.origin === "gbucket"
                                        ? "danger"
                                        : "primary"
                            }
                        >
                            {variableConfig?.origin}
                        </Badge>
                    )}
                </Col>
                <Col xs={3} className="name-col">
                    <Form.Label className="me-2">{name}</Form.Label>
                    {variableConfig.description && (
                        <Description description={variableConfig.description}/>
                    )}
                </Col>
                <Col xs={8} className="align-top">
                    {formVariable}
                    {location && unsetVariable && variableConfig?.origin && location === variableConfig?.origin && (
                        <Button
                            onClick={() => unsetVariable(moduleName, name, location, slot, formIdentifier)}
                            variant="secondary"
                            className={unsetClass}
                        ></Button>
                    )}
                </Col>
            </Form.Group>
        </Container>
    );
};

export default ContextVariable;