import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import Form from "react-bootstrap/Form";
import { IVariable } from "../../../types";
import { useFormContext } from "react-hook-form";
import { useConfiguratorContext } from "../../../hooks/configuratorContext";
import { debounce } from "lodash"; // Import lodash debounce or implement your own
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

type SelectType = {
        type: "select";
        default?: string[];
        value?: string[];
        options: string[];
        maxSelect?: number;
        minSelect?: number;
        description: string;
        readOnly: boolean;
        hidden: boolean;
}

const ContextSelectVariable: FC<IVariable> = ({ name, location, groupId, moduleName, formIdentifier, isVariableGroup, header }) => {
    const { mergedConfig, addToLocalModuleConfig, addToLocalInventoryConfig } = useConfiguratorContext();
    const methods = useFormContext();

    const variableConfig = useMemo((): SelectType | undefined => {
        if (!location || !groupId || !moduleName) return;

        const configCopy = ["site", "preset"].includes(location)
            ? mergedConfig?.moduleConfig[groupId]
            : mergedConfig?.inventoryConfig[groupId];

        if (!configCopy) return;

        return isVariableGroup && header
            ? configCopy[moduleName]?.[header]?.[name]
            : configCopy[moduleName]?.[name];
    }, [mergedConfig]);

    // UI states for validation feedback
    const [isInValid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const readOnly = variableConfig?.readOnly;
    const defaultValue = variableConfig?.default || [];
    const value = variableConfig?.value || defaultValue;
    const options = variableConfig?.options || [];
    const minSelect = variableConfig?.minSelect ?? 1;
    const maxSelect = variableConfig?.maxSelect ?? 1;
    const multiSelect = minSelect > 1 || maxSelect > 1;

    // Debounced change handler to improve performance
    const debouncedHandleChange = useCallback(
        debounce((newOptions: string[]) => {
            // Invoke update functions based on the location
            if (location && ["site", "preset"].includes(location) && addToLocalModuleConfig) {
                addToLocalModuleConfig(moduleName, name, newOptions, formIdentifier);
            } else if (location && groupId && ["inventory", "preset-inventory"].includes(location) && addToLocalInventoryConfig) {
                addToLocalInventoryConfig(moduleName, name, groupId, newOptions, formIdentifier);
            }
        }, 100),
        [addToLocalModuleConfig, addToLocalInventoryConfig, location, groupId, moduleName, name, formIdentifier]
    );

    // Validation helpers
    const resetValidation = () => {
        setIsInvalid(false);
        setErrorMessage("");
    };

    const runValidation = (val: string[]) => {
        resetValidation();

        if (minSelect > 0 && val.length < minSelect) {
            setIsInvalid(true);
            setErrorMessage(`Please select at least ${minSelect} option(s).`);
            return;
        }

        if (val.length > maxSelect) {
            setIsInvalid(true);
            setErrorMessage(`Please select at most ${maxSelect} option(s).`);
            return;
        }
    };

    // Handle change event
    const handleChange = (newOptions: any) => {
        // const newOptions = Array.from(event.target.selectedOptions).map(
        //     (option: any) => option.value).filter((value) => options.includes(value)
        // );
        methods.setValue(formIdentifier, newOptions);
        debouncedHandleChange(newOptions);

        runValidation(newOptions);
    };

    useEffect(() => {
        methods.setValue(formIdentifier, value);
    }, [formIdentifier, methods, value]);

    // Register the input for form validation
    useEffect(() => {
        methods.register(formIdentifier);
    }, [formIdentifier, methods]);

    useEffect(() => {
        runValidation(value);
    }, [value]);

    return (
        <div>
            <Typeahead
                id={name}
                multiple={multiSelect}
                onChange={handleChange}
                options={options}
                selected={value}
                isInvalid={isInValid}
                className="float-start me-1"
                clearButton={true}
                style={{
                    maxWidth: "500px",
                }}
            />
            <Form.Control.Feedback
                type="invalid"
                style={{ clear: "both", float: "left" }}
            >
                {errorMessage}
            </Form.Control.Feedback>
        </div>
    );
};

export default ContextSelectVariable;
