import React, { FC, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { useConfiguratorContext } from '../../hooks/configuratorContext';
import ConfigNavigation from './ConfigNavigation';
import ContextModules from './context/ContextModules';

/**
 * Interface describing the props of ModuleConfig
 */
interface IModuleConfig {
    location: string;
}

/**
 * A component for displaying configurable modules.
 * Allows toggling which module groups should be shown.
 */
const ModuleConfig: FC<IModuleConfig> = ({ location }) => {
    // State that holds the currently selected module groups (by their IDs)
    const [selectedModuleGroup, setSelectedModuleGroup] = useState<string[]>([]);

    // Pull moduleGroups and builderConfig from the configurator context
    const { moduleGroups, builderConfig } = useConfiguratorContext();

    /**
     * Toggles a module group in/out of the selected list.
     * If a group is currently selected, remove it; otherwise, add it.
     *
     * @param moduleGroup - The module group ID to toggle
     */
    const handleSelectedModuleGroup = (moduleGroup: string) => {
        if (selectedModuleGroup.includes(moduleGroup)) {
            // Remove the group if it's already selected
            setSelectedModuleGroup(prev =>
                prev.filter(group => group !== moduleGroup)
            );
        } else {
            // Otherwise, add it
            setSelectedModuleGroup(prev => [...prev, moduleGroup]);
        }
    };

    return (
        <>
            <Row>
                {/* Left column: Navigation component to list and select module groups */}
                <Col lg={3} xs={12}>
                    {moduleGroups && (
                        <ConfigNavigation
                            elements={moduleGroups}
                            selectedElements={selectedModuleGroup}
                            handleSelect={handleSelectedModuleGroup}
                        />
                    )}
                </Col>

                {/* Right column: Displays the modules from selected groups */}
                <Col lg={9} xs={12}>
                    {moduleGroups &&
                        builderConfig &&
                        moduleGroups.map(moduleGroup => {
                            // Determine whether to show this module group:
                            // Show all groups if none is selected, or only selected groups if any are selected
                            const shouldDisplay =
                                selectedModuleGroup.length === 0 ||
                                selectedModuleGroup.includes(moduleGroup);

                            return (
                                <div
                                    key={moduleGroup}
                                    style={{ display: shouldDisplay ? 'block' : 'none' }}
                                >
                                    <h4>{moduleGroup}</h4>
                                    <div className="sloth-box">
                                        {/* ContextModules is responsible for rendering modules within a group */}
                                        <ContextModules groupId={moduleGroup} location={location} />
                                    </div>
                                </div>
                            );
                        })}
                </Col>
            </Row>
        </>
    );
};

export default ModuleConfig;
