import React, { FC, useRef } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

import { useConfiguratorContext } from '../../../hooks/configuratorContext';
import { searchNestedObject } from '../../../utils/utils';
import { ActiveBadge } from '../../../styles/CommonComponents';
import Description from '../../Description';
import VariableGroup from '../VariableGroup';
import ContextVariable from './ContextVariable';

/**
 * Describes props passed to ContextModules
 */
type IContextModules = {
    groupId: string;
    location: string;
};

/**
 * Renders a list of modules (and their variables) within an accordion.
 * Modules can be sorted, filtered, and expanded to show child variables.
 */
const ContextModules: FC<IContextModules> = ({ groupId, location }) => {
    // Pull context data from the configurator
    const { builderConfig, mergedConfig } = useConfiguratorContext();

    /**
     * Depending on the location prop, select the correct portion
     * of mergedConfig (moduleConfig vs. inventoryConfig).
     */
    let mergedConfigCopy: any = ['site', 'preset'].includes(location)
        ? mergedConfig?.moduleConfig[groupId]
        : mergedConfig?.inventoryConfig[groupId];

    /**
     * Store references to the Accordion.Body elements so we can
     * adjust textarea heights dynamically inside each one.
     */
    const accordionBodyRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    /**
     * Adjusts the height of all textareas within the accordion body
     * for the specified module.
     *
     * @param moduleName - Name/ID of the module whose textareas should be resized.
     */
    const adjustTextareaHeights = (moduleName: string) => {
        const bodyRef = accordionBodyRefs.current[moduleName];
        if (bodyRef) {
            // Use setTimeout to ensure the DOM is updated before measuring scrollHeight
            setTimeout(() => {
                const textareas = bodyRef.querySelectorAll('textarea');
                textareas.forEach((textarea: HTMLTextAreaElement) => {
                    textarea.style.height = 'auto'; // Reset current height
                    textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
                });
            }, 0);
        }
    };

    return (
        <Row>
            <Col className="align-items-center">
                <Accordion>
                    {mergedConfigCopy && Object.keys(mergedConfigCopy).length > 0 ? (
                        // Filter out hidden modules, then sort by "active" status, finally by name
                        Object.keys(mergedConfigCopy)
                            .filter(module => {
                                // Check if the module is set to hidden in the builderConfig
                                const moduleBuilderConfig = searchNestedObject(builderConfig, module);
                                return moduleBuilderConfig?.meta?.hidden !== true;
                            })
                            .sort((a, b) => {
                                // Determine "active" status for both modules a and b
                                const aIsActive =
                                    mergedConfigCopy[a]?.active?.value !== undefined &&
                                    mergedConfigCopy[a]?.active?.value !== null
                                        ? !!mergedConfigCopy[a]?.active?.value
                                        : !!mergedConfigCopy[a]?.active?.default;

                                const bIsActive =
                                    mergedConfigCopy[b]?.active?.value !== undefined &&
                                    mergedConfigCopy[b]?.active?.value !== null
                                        ? !!mergedConfigCopy[b]?.active?.value
                                        : !!mergedConfigCopy[b]?.active?.default;

                                // First, sort by active/inactive, then alphabetically
                                if (aIsActive === bIsActive) {
                                    return a.localeCompare(b);
                                }
                                return aIsActive ? -1 : 1;
                            })
                            .map(module => {
                                // Grab module metadata from builderConfig
                                const builderMeta = searchNestedObject(builderConfig, module)?.meta;

                                // Determine if module is active
                                const isActive =
                                    mergedConfigCopy[module]?.active?.value !== undefined &&
                                    mergedConfigCopy[module]?.active?.value !== null
                                        ? !!mergedConfigCopy[module]?.active?.value
                                        : !!mergedConfigCopy[module]?.active?.default;

                                // If module is valid (non-empty string and not 'meta'), render it
                                if (module && module !== 'meta') {
                                    return (
                                        <Accordion.Item eventKey={module} key={module}>
                                            <Accordion.Header>
                                                <Row className="w-35">
                                                    {/* Active badge indicating whether module is active */}
                                                    <Col
                                                        xs="auto"
                                                        className="d-flex align-items-center justify-content-start"
                                                    >
                                                        <ActiveBadge active={isActive} />
                                                    </Col>

                                                    {/* Display the module name (fallback to the module ID if no name is present) */}
                                                    <Col
                                                        xs="auto"
                                                        className="d-flex align-items-center justify-content-start"
                                                    >
                                                        {builderMeta?.name || module}
                                                    </Col>

                                                    {/* If a description is present, render a tooltip-like icon */}
                                                    <Col
                                                        xs="auto"
                                                        className="d-flex align-items-center justify-content-start"
                                                    >
                                                        {builderMeta?.description && (
                                                            <Description description={builderMeta.description} />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Accordion.Header>

                                            <Accordion.Collapse
                                                eventKey={module}
                                                onEntered={() => adjustTextareaHeights(module)}
                                            >
                                                {/*
                          We assign a ref to the Accordion.Body so we can
                          dynamically adjust textarea heights within that section.
                        */}
                                                <Accordion.Body
                                                    ref={(el: HTMLDivElement | null) => {
                                                        accordionBodyRefs.current[module] = el;
                                                    }}
                                                >
                                                    {/* Loop through all variables in the module */}
                                                    {Object.keys(mergedConfigCopy[module]).map(variable => {
                                                        // Only render if the variable is not marked as hidden
                                                        if (
                                                            mergedConfigCopy[module][variable] &&
                                                            !mergedConfigCopy[module][variable].hidden
                                                        ) {
                                                            // Decide whether to render a single variable or a group of variables
                                                            return !mergedConfigCopy[module][variable].meta ? (
                                                                <ContextVariable
                                                                    key={`${module}-${variable}`}
                                                                    name={variable}
                                                                    moduleName={module}
                                                                    groupId={groupId}
                                                                    location={location}
                                                                />
                                                            ) : (
                                                                <VariableGroup
                                                                    key={`${module}-${variable}`}
                                                                    headerName={variable}
                                                                    variables={mergedConfigCopy[module][variable]}
                                                                    moduleName={module}
                                                                    location={location}
                                                                    groupId={groupId}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Collapse>
                                        </Accordion.Item>
                                    );
                                }
                                // If the module is invalid or otherwise hidden, return null
                                return null;
                            })
                    ) : (
                        // Fallback if no configuration is found
                        <p>No config found, please contact your administrator</p>
                    )}
                </Accordion>
            </Col>
        </Row>
    );
};

export default ContextModules;
