import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import Form from "react-bootstrap/Form";
import { IVariable } from "../../../types";
import { useFormContext } from "react-hook-form";
import { useConfiguratorContext } from "../../../hooks/configuratorContext";
import { debounce } from "lodash"; // Import lodash debounce or implement your own

const ContextBooleanVariable: FC<IVariable & { onToggle: (isChecked: boolean) => void }> = ({
                                                                                                name, location, groupId, moduleName, formIdentifier, isVariableGroup, header, onToggle
                                                                                            }) => {
    const { mergedConfig, addToLocalModuleConfig, addToLocalInventoryConfig } = useConfiguratorContext();
    const methods = useFormContext();

    const variableConfig = useMemo(() => {
        if (!location || !groupId || !moduleName) return null;

        const configCopy = ["site", "preset"].includes(location)
            ? mergedConfig?.moduleConfig[groupId]
            : mergedConfig?.inventoryConfig[groupId];

        if (!configCopy) return null;

        return isVariableGroup && header
            ? configCopy[moduleName]?.[header]?.[name]
            : configCopy[moduleName]?.[name];
    }, [mergedConfig]);


    const readOnly = variableConfig?.readOnly;
    const [checked, setChecked] = useState(variableConfig?.value ?? variableConfig?.default ?? false);

    // Debounced change handler to improve performance
    const debouncedHandleChange = useCallback(
        debounce((newChecked: boolean) => {
            // Invoke update functions based on the location
            if (location && ["site", "preset"].includes(location) && addToLocalModuleConfig) {
                addToLocalModuleConfig(moduleName, name, newChecked, formIdentifier);
            } else if (location && groupId && ["inventory", "preset-inventory"].includes(location) && addToLocalInventoryConfig) {
                addToLocalInventoryConfig(moduleName, name, groupId, newChecked, formIdentifier);
            }
            onToggle(newChecked);
        }, 100),
        [addToLocalModuleConfig, addToLocalInventoryConfig, location, groupId, moduleName, name, formIdentifier, onToggle]
    );


    // Update the checked state and form value when variableConfig changes
    useEffect(() => {
        const newChecked = variableConfig?.value ?? variableConfig?.default ?? false;
        setChecked(newChecked);
        methods.setValue(formIdentifier, newChecked); // Update form state with the initial value
    }, [variableConfig, methods, formIdentifier]);

    // Register the input for form validation
    useEffect(() => {
        methods.register(formIdentifier);
    }, [formIdentifier, methods]);


    // Handle change event
    const handleChange = () => {
        const newChecked = !checked;
        setChecked(newChecked); // Update local state immediately
        methods.setValue(formIdentifier, newChecked); // Sync form state
        debouncedHandleChange(newChecked); // Invoke debounced logic
    };

    return (
        <Form.Check
            className="float-start me-1"
            type="switch"
            id={name}
            disabled={readOnly || false}
            checked={checked}
            onChange={handleChange}
        />
    );
};

export default ContextBooleanVariable;
